.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    .title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .content {
        margin-left: 100px;
        .title3 .fishbox-link{
            color: #4EE7FF;
            
        }
        .title3 .tanktrouble-link{
            color: #4EE7FF;
            
        }
        .title3 .songguess-link{
            color: #4EE7FF;
        }
    }

    .title1{
        width: 100%;
    }

    .flat-button {
        color: #4EE7FF;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #4EE7FF;
        float: left;
        animation-delay: 14s;
        animation: fadeInAnimation 1s 18s backwards;
    
    
        white-space: nowrap;
    
        &:hover {
          background: #4EE7FF;
          color: #000000;
        }
      }

    // .images-container {
    //     display: flex;
    //     gap: 15px;
    //     flex-wrap: wrap;
    //     padding-bottom: 100px;
    // }

    // .image-box {
    //     position: relative;
    //     flex: 1 1 20%;
    //     height: 400px;
    //     overflow: hidden;
    //     border-radius: 10px;
    //     max-width: calc(25% - 10px);

    //     .portfolio-image {
    //         position: absolute;
    //         z-index: 2;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //     }

    //     .content {
    //         position: absolute;
    //         width: 100%;
    //         z-index: 3;
    //         padding: 10px 20px;
    //         transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    //         background: linear-gradient(
    //             180deg,
    //             rgba(0, 0, 0, 0.2) 0,
    //             rgba(0, 0, 0, 1)
    //         );
    //         bottom: -70px;
    //     }

    //     .title {
    //         margin-bottom: 0;
    //         margin-top: 0;
    //         color: #fff;
    //         font-size: 24px;
    //         font-weight: 500;
    //         line-height: 24px;
    //     }

    //     .description {
    //         font-size: 14px;
    //         margin-bottom: 5px;
    //         color: #fff;
    //         font-weight: 700;
    //     }

    //     .btn {
    //         margin-top: 30px;
    //         margin-bottom: 10px;
    //         padding: 0 23px;
    //         height: 40px;
    //         line-height: 34px;
    //         border: 2px solid #4EE7FF;
    //         border-radius: 4px;
    //         font-size: 14px;
    //         color: #fff;
    //         background: transparent;
    //         text-transform: uppercase;
    //         font-weight: 700;
    //         transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    //         cursor: pointer;
    //     }

    //     .btn:hover {
    //         transform: translateY(-3px);
    //         background: #4EE7FF;
    //     }

    //     &:after {
    //         content: "";
    //         background: linear-gradient(180deg, #4EE7FF, #000);
    //         position: absolute;
    //         left: 0;
    //         right: 0;
    //         bottom: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         z-index: 2;
    //         transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    //         opacity: 0;
    //     }

    //     &:hover:after {
    //         opacity: 0.85;
    //     }

    //     &:hover .content {
    //         bottom: 0;
    //         background: transparent;
    //     }
    // }

    // .video-responsive {
    //     overflow: hidden;
    //     padding-bottom: 56.25%;
    //     position: relative;
    //     height: 0;
    //   }
      
    //   .video-responsive iframe {
    //     left: 0;
    //     top: 0;
    //     height: 100%;
    //     width: 100%;
    //     position: absolute;
    //   }
}